import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from 'react';
import { Grid, Button, Typography, Card, Box, TextField, Paper } from '@mui/material';
// components
import Page from '../components/Page';
import { getRequest, imgPostRequest, postRequest, updateRequest } from 'src/utils/api';
import imgPreview from '../assets/image-preview.jpg';

// ----------------------------------------------------------------------
export default function ReportContent() {
  const [reportImgUrl, setReportImgUrl] = useState(null);
  const [reportImgUrl2, setReportImgUrl2] = useState(null);
  const [reportImgUrl3, setReportImgUrl3] = useState(null);
  const [rpCtn, setReportContent] = useState([]);
  const [reportInfo, setReportInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const handleReportInfo = (e) => {
    const newInfo = { ...reportInfo };
    newInfo[e.target.id.split('-')[0]] = e.target.value;
    setReportInfo(newInfo);
  };

  const handleImgUpload = async (img, status) => {
    setLoading(true);
    const isSucceed = await imgPostRequest(img);

    if (isSucceed) {
      switch (status) {
        case 1:
          setReportImgUrl(isSucceed);
          break;
        case 2:
          setReportImgUrl2(isSucceed);
          break;
        case 3:
          setReportImgUrl3(isSucceed);
          break;
        default:
      }
    }
    console.log(isSucceed);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = reportInfo;
    body['img_url'] = reportImgUrl;
    body['img2_url'] = reportImgUrl2;
    body['img3_url'] = reportImgUrl3;
    let isSucceed;
    if (rpCtn?.id) {
      isSucceed = await updateRequest(
        `updateReportContent/${rpCtn?.id}`,
        reportInfo,
        'Report Content'
      );
    } else {
      isSucceed = await postRequest('addReportContent', reportInfo, 'Report Content');
    }
    if (isSucceed) {
      setReload(!reload);
    }
  };

  const handleGetRequest = async () => {
    const data = await getRequest('getReportContent');
    setReportContent(data[0]);
  };
  useEffect(() => {
    handleGetRequest();
  }, [reload]);

  const hdStl = {
    width: '40%'
  };
  const hdrBox = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center'
  };
  const container = {
    marginTop: '20px',
    marginLeft: '5%',
    marginRight: '5%'
  };
  const imgBox = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px'
  };
  const imgContainer = {
    // display
    display: 'greed',
    justifyContent: 'space-around',
    marginTop: '20px',
    marginBottom: '20px',
    alignItems: 'center'
  };
  return (
    <Page title="Getting Started">
      <Card sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h4" gutterBottom>
              Report Content
            </Typography>
          </Grid>
        </Grid>

        <Box sx={container}>
          <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
            <Box sx={hdrBox}>
              <TextField
                id="head1"
                onBlur={(e) => handleReportInfo(e)}
                sx={hdStl}
                label={rpCtn?.head1 || 'INSIGHT REPORT'}
                variant="standard"
              />
            </Box>
            <TextField
              id="para1"
              onBlur={(e) => handleReportInfo(e)}
              fullWidth
              label={rpCtn?.para1 || 'INSIGHT REPORT Accelerating...'}
              variant="standard"
            />
            <Box sx={hdrBox}>
              <TextField
                id="head2"
                sx={hdStl}
                onBlur={(e) => handleReportInfo(e)}
                label={rpCtn?.head2 || 'OVERALL HAPPINESS FACTOR'}
                variant="standard"
              />
            </Box>
            <TextField
              id="para2"
              fullWidth
              onBlur={(e) => handleReportInfo(e)}
              label={rpCtn?.para2 || 'The overall hapiness score...'}
              variant="standard"
            />
            <Box sx={hdrBox}>
              <TextField
                id="head3"
                sx={hdStl}
                onBlur={(e) => handleReportInfo(e)}
                label={rpCtn?.head3 || 'TARGET CULTURAL STATEMENTS'}
                variant="standard"
              />
            </Box>
            <TextField
              id="para3"
              fullWidth
              onBlur={(e) => handleReportInfo(e)}
              label={rpCtn?.para3 || 'Showcasing the gap in current...'}
              variant="standard"
            />
            <Box sx={hdrBox}>
              <TextField
                id="head4"
                sx={hdStl}
                onBlur={(e) => handleReportInfo(e)}
                label={rpCtn?.head4 || 'CURRENT CULTURAL STATEMENTS'}
                variant="standard"
              />
            </Box>
            <TextField
              id="para4"
              fullWidth
              onBlur={(e) => handleReportInfo(e)}
              label={rpCtn?.para4 || 'Showcasing the gap in current...'}
              variant="standard"
            />
            <Box sx={hdrBox}>
              <TextField
                id="head5"
                sx={hdStl}
                onBlur={(e) => handleReportInfo(e)}
                label={rpCtn?.head5 || 'OPERATIONAL DIAGNOSIS'}
                variant="standard"
              />
            </Box>
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                <Paper elevation={7} sx={imgBox}>
                  <img
                    style={{ width: '150px', height: '150px' }}
                    src={reportImgUrl ? reportImgUrl : rpCtn?.img_url ? rpCtn?.img_url : imgPreview}
                    alt="logo"
                  />
                  <Box>
                    <Button sx={{ my: 0 }} variant="contained" component="span">
                      <input
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        type="file"
                        onChange={(e) => handleImgUpload(e.target.files[0], 1)}
                      />
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4} md={6}>
                <Paper elevation={7} sx={imgBox}>
                  <img
                    style={{ width: '150px', height: '150px' }}
                    src={
                      reportImgUrl2 ? reportImgUrl2 : rpCtn?.img2_url ? rpCtn?.img2_url : imgPreview
                    }
                    alt="logo"
                  />
                  <Box>
                    <Button sx={{ my: 0 }} variant="contained" component="span">
                      <input
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        type="file"
                        onChange={(e) => handleImgUpload(e.target.files[0], 2)}
                      />
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4} md={6}>
                <Paper elevation={7} sx={imgBox}>
                  <img
                    style={{ width: '150px', height: '150px' }}
                    src={
                      reportImgUrl3 ? reportImgUrl3 : rpCtn?.img3_url ? rpCtn?.img3_url : imgPreview
                    }
                    alt="logo"
                  />
                  <Box>
                    <Button sx={{ my: 0 }} variant="contained" component="span">
                      <input
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                        type="file"
                        onChange={(e) => handleImgUpload(e.target.files[0], 3)}
                      />
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                type="submit"
                sx={{ mt: 2, width: '30%' }}
                color="success"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >
                {rpCtn?.id ? 'Update' : 'Save'}
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Card>
    </Page>
  );
}
